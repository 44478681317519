import EmailIcon from '@mui/icons-material/Email';
import ContactInfo from './ContactInfo';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Terminal from "./Terminal";


function App() {

  const text = (
    <>
        Hej! <br />
        Jag heter Carl, och jag tycker om att bygga saker.
        <br />
        Just nu bygger jag molntjänster på Actia Nordic.
      <ContactInfo Icon={EmailIcon} link="mailto:carl@carlbjork.com" text="carl@carlbjork.com" />
      <ContactInfo Icon={LinkedInIcon} link="https://www.linkedin.com/in/carl-björk-127ab6223" text="Carl Björk" />
      <br/>
    </>
  )
  return (
    <Terminal path="~/hemsida" cmd="cat hej.txt" text={text} />
  )
};

export default App;