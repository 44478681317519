import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import ContactInfo from './ContactInfo';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  HashRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { Grid } from '@mui/material';

const paperStyle = {
  position: 'relative',
  color: '#fff',
  mb: 4,
  border: '1px solid rgb(71,71,71)',
  borderRadius: 2,
  mt: 1,
  minWidth: '500px',
  overflowX: 'auto',
};

const topBarStyle = {
  position: 'absolute',
  backgroundColor: 'rgb(36,36,36)',
  top: 0,
  bottom: 25,
  left: 0,
  right: 0,
  borderTopLeftRadius: 7.5,
  borderTopRightRadius: 7.5,
};

const mainWindowStyle = {
  position: 'relative',
  pl: 1,
  pr: 1,
  mt: 3,
  borderBottomLeftRadius: 7.5,
  borderBottomRightRadius: 7.5,
  backgroundColor: 'rgb(31,30,31)',
  minWidth: '500px',
  overflowX: 'auto',
  minHeight: '500px',
  maxHeight: '500px',
  overflowY: 'auto',
};

const textHej = (
  <>
    Hej! <br />
    Jag heter Carl, och jag tycker om att bygga saker.
    <br />
    Just nu bygger jag molntjänster på Actia Nordic.
    <br/><br/>
    <ContactInfo Icon={EmailIcon} link="mailto:carl@carlbjork.com" text="carl@carlbjork.com" />
    <ContactInfo Icon={LinkedInIcon} link="https://www.linkedin.com/in/carl-björk-127ab6223" text="Carl Björk" />
    <br/>
  </>
)

const textCv = (
  <>
    <div>
      <b> ANSTÄLLNINGAR </b> <br/>
        <div style={{ marginLeft: "20px"}}>
          <Grid container  spacing={1}>
            <Grid item xs={4}>
              * <i><b>Software Development Engineer</b></i>
            </Grid>
            <Grid item xs={2}>
              @ Actia Nordic
            </Grid>
            <Grid item xs={6}>
              {"[Feb 2023 - ]"}
            </Grid>
            <Grid item xs={6}>
              <div style={{ marginLeft: "20px"}}>
                Övergång från konsult till anställning. Större fokus på fullstack-utveckling, Scrum Master.
              </div>
            </Grid>
          </Grid>
          <br/>
          <Grid container  spacing={1}>
            <Grid item xs={4}>
              * <i><b>Embedded Developer</b></i> <br/>
            </Grid>
            <Grid item xs={2}>
              @ Tech Talents
            </Grid>
            <Grid item xs={6}>
              {"[Jan 2022 - Feb 2023]"}
            </Grid>
            <Grid item xs={6}>
              <div style={{ marginLeft: "20px"}}>
              Placerad på Actia Nordic. Fullstack-utveckling, Scrum Master, Embedded Linux, support.
              </div>
            </Grid>
          </Grid>
        </div> <br/>
      <b> UTBILDNING </b> <br/>
        <div style={{ marginLeft: "20px"}}>
          <Grid container  spacing={1}>
            <Grid item xs={4}>
              * <i><b>Tek.kand. Elektroteknik</b></i>
            </Grid>
            <Grid item xs={2}>
            @ LiU
            </Grid>
            <Grid item xs={6}>
              {"[Aug 2017 - Jan 2022]"}
            </Grid>
            <Grid item xs={6}>
              <div style={{ marginLeft: "20px"}}>
                Fokus på digital elektronik och embedded.
              </div>
            </Grid>
          </Grid>
        </div>
      <br/>
      <b> TEKNOLOGIER </b> <br/>
        <div style={{ marginLeft: "20px"}}>
          <Grid container spacing={2}>
            <Grid item>Python</Grid>
            <Grid item>PosgreSQL</Grid>
            <Grid item>JS</Grid>
            <Grid item>React</Grid>
            <Grid item>Docker</Grid>
            <Grid item>Kubernetes</Grid>
            <Grid item>gRPC</Grid>
            <Grid item>MQTT</Grid>
            <Grid item>C</Grid>
          </Grid>
        </div>
    </div>
  </>
)

const Dot = ({ left, color }) => (
  <Box
    sx={{
      position: 'absolute',
      width: 13,
      height: 13,
      top: 6,
      left,
      borderRadius: '50%',
      backgroundColor: color,
    }}
  />
);

const Prompt = ({ path, cmd }) => (
  <>
    <Typography color="rgb(93,168,79)" sx={{ display: 'flex', alignItems: 'center' }}>
      carl@webserver
      <Typography color="rgb(255,255,255)">:</Typography>
      <Typography color="rgb(95,190,204)" sx={{ display: 'flex', alignItems: 'center' }}>
        {path}
        <Typography color="rgb(255,255,255)">
          {cmd ? `$ ${cmd}` : '$ '}
          {cmd ? '' : <span className="cursor">_</span>}
        </Typography>
      </Typography>
    </Typography>
  </>
);

function Hej({path}) {
  return (
    <>
      <Prompt path={path} cmd="cat hej.txt" />
        {textHej}
      <Prompt path={path} />
    </>
  )
}

function Cv({path}) {
  return (
    <>
    <Prompt path={path} cmd="cat cv.txt" />
      {textCv}
    <Prompt path={path} />
  </>
  )
}

const Links = ({path }) => (
  <>
    <Prompt path={path} cmd="ls"/>
      <Grid container spacing={5}>
        <Grid item>
          <Link style={{ color: 'white' }} to="/">hej.txt</Link>
        </Grid>
        <Grid item>
          <Link style={{ color: 'white' }} to="/cv">cv.txt</Link>
        </Grid>
      </Grid>
  </>
)

function Terminal({ path, cmd, text }) {
  const dots = [
    { left: 10, color: 'rgb(239,106,94)' },
    { left: 30, color: 'rgb(246,190,79)' },
    { left: 50, color: 'rgb(95,196,84)' },
  ];


  return (
    <Paper sx={paperStyle}>
      <Box sx={topBarStyle}>
        {dots.map((dot, index) => (
          <Dot key={index} {...dot} />
        ))}
      </Box>
        <Box sx={mainWindowStyle}>
          <Router>
            <Links path={path}/>
            <Routes>
              <Route path="/" element={<Hej path={path}/>}/>
              <Route path="/cv" element={<Cv path={path}/>}/>
            </Routes>
          </Router>
        </Box>
    </Paper>
  );
}
export default Terminal;
