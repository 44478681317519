import Link from '@mui/material/Link'

const ContactInfo = ({ Icon, link, text }) => (
  <>
    <Icon
      fontSize="small"
      sx={{
        display: 'inline-flex',
        verticalAlign: 'middle',
      }}
    />
    {' '}
    <Link href={link} target="_blank" rel="noopener noreferrer" color="inherit" underline="hover">
      {text}
    </Link>
    <br />
  </>
);

export default ContactInfo;