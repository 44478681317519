import { createTheme } from '@mui/material/styles/';

let MyTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#066ad8',
    },
    secondary: {
      main: '#4ae416',
    },
    background: {
      default: 'rgb(29,29,29)',
      paper: '#0b192b',
    },
    text: {
      primary: 'rgba(247,245,245,0.87)',
      secondary: 'rgba(199,199,199,0.54)',
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: 'Roboto Mono',
  },
});

export default MyTheme;

//      background: {
//   default: '#061e3a',
//   paper: '#0b192b',
// },