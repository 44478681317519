import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import MyTheme from './MyTheme'
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={MyTheme}>
      <CssBaseline />
      <Container maxWidth="md">
        <main>
          <App/>
        </main>
      </Container>
    </ThemeProvider>
  </React.StrictMode>
);
